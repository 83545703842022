.App {
  text-align: center;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px; /* reduced from 20px */
  background-color: #0000;
}

.logo {
  padding: 20px 20px 20px 30px;
  height: 10vmin; /* reduced from 40vmin */
  min-width: 50px; /* adjust as needed */
  min-height: 50px; /* adjust as needed */
  transition: height 1s ease; /* Smooth transition for height changes */
}

.login-btn {
  padding: 5px 50px;
  color: #ffffff;
  background-color: #000;
  border: none;
  cursor: pointer;
  font-size: calc(25px + 1.5vw); /* Base size + scalable part */
  font-family: 'Brush Script MT', cursive;
  transition: font-size 1s ease; /* Smooth transition for font-size changes */
}

@media screen and (min-width: 600px) {
  .login-btn {
    font-size: 50px; /* Fixed size for larger screens */
  }
}